import React from 'react'
import Flickity from 'react-flickity-component'

import * as styles from '../styles/locations.module.sass'

const Locations = ({ locations, setMapLocations }) => {
  const flickityOptions = {
    pageDots: false,
    cellAlign: 'left',
    freeScroll: true,
    contain: true,
    groupCells: '80%',
    arrowShape: {
      x0: 30,
      x1: 60,
      y1: 40,
      x2: 60,
      y2: 20,
      x3: 60,
    },
  }

  // Only because we want EN location root (US) to go first.
  locations.sort((loc1, loc2) => {
    if (loc1.parentId === null && loc2.parentId === null) {
      return loc1.name > loc2.name ? -1 : 1
    } else {
      return loc1.name.localeCompare(loc2.name)
    }
  })

  const setActive = (el) => {
    ;[...document.querySelectorAll('[class*="locations-module"] a')].forEach((el) => el.classList.remove('active'))
    el.classList.add('active')
  }

  const handleLocationClick = (location) => (e) => {
    e.preventDefault()
    setActive(e.target)
    setMapLocations([location])
  }

  return (
    <div className={styles.locations}>
      {locations
        .filter((location) => location.parentId === null)
        .map((parentLocation) => (
          <Flickity
            className={styles.carousel} // default ''
            options={flickityOptions} // takes flickity options {}
            key={parentLocation.id}
          >
            {locations
              .filter((location) => location.posts.nodes.length > 0 && location.parentId === parentLocation.id)
              .map((location) => (
                <a
                  key={location.id}
                  href={`#${location.slug}`}
                  // onMouseEnter={() => setMapLocations([location])}
                  // onMouseLeave={() => setMapLocations(articleLocations)}
                  onClick={handleLocationClick(location)}
                >
                  {location.name}
                </a>
              ))}
          </Flickity>
        ))}
    </div>
  )
}

export default Locations
