import React, { useEffect, useRef } from 'react'
import L from 'leaflet'
import { withPrefix } from 'gatsby'
import { useWindowWidth } from 'hooks'
// import { getSiblings } from 'utils'

import * as styles from '../styles/map.module.sass'

const mapAreaSVG =
  '<svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14"><path fill="#FFFFFE" fill-rule="evenodd" d="M15.7905 11.4502C15.6705 11.3272 15.5955 11.1552 15.5955 10.9692L15.5955 3.0332C15.5955 2.6562 15.8935 2.3502 16.2615 2.3502 16.6305 2.3502 16.9275 2.6552 16.9275 3.0322L16.9275 10.9672C16.9285 11.3442 16.6295 11.6492 16.2635 11.6492 16.0785 11.6492 15.9105 11.5732 15.7905 11.4502M12.0559 13.3015C11.9359 13.1785 11.8609 13.0065 11.8619 12.8195L11.8619 1.1825C11.8619.8055 12.1599.4995 12.5279.4995 12.8969.4995 13.1939.8045 13.1939 1.1825L13.1939 12.8205C13.1939 13.1955 12.8949 13.5005 12.5269 13.4995 12.3439 13.5005 12.1759 13.4245 12.0559 13.3015M8.3223 13.3018C8.2023 13.1788 8.1273 13.0068 8.1283 12.8188L8.1283 1.1818C8.1283.8058 8.4263.4998 8.7943.4998 9.1633.4998 9.4603.8048 9.4603 1.1818L9.4603 12.8208C9.4603 13.1958 9.1623 13.5008 8.7933 13.4998 8.6103 13.5008 8.4423 13.4248 8.3223 13.3018M19.5225 8.0762C19.4025 7.9522 19.3275 7.7812 19.3285 7.5932L19.3285 6.4072C19.3285 6.0302 19.6275 5.7242 19.9955 5.7242 20.3645 5.7242 20.6615 6.0292 20.6615 6.4062L20.6605 7.5942C20.6605 7.9702 20.3625 8.2752 19.9925 8.2752 19.8105 8.2752 19.6425 8.1992 19.5225 8.0762M4.5901 11.4502C4.4701 11.3272 4.3951 11.1552 4.3951 10.9692L4.3941 3.0332C4.3951 2.6562 4.6931 2.3502 5.0611 2.3502 5.4301 2.3502 5.7271 2.6552 5.7271 3.0332L5.7271 10.9672C5.7281 11.3442 5.4291 11.6492 5.0631 11.6492 4.8781 11.6492 4.7101 11.5732 4.5901 11.4502M.8552 8.0759C.7352 7.9529.6602 7.7809.6612 7.5929L.6612 6.4069C.6612 6.0299.9592 5.7249 1.3272 5.7249 1.6962 5.7249 1.9942 6.0289 1.9942 6.4069L1.9932 7.5949C1.9932 7.9699 1.6942 8.2749 1.3252 8.2749 1.1432 8.2759.9752 8.1989.8552 8.0759"/></svg>'

const topLeft = (top, left) => {
  if (L.Util.isArray(top)) {
    return [-top[1], top[0]]
  }
  return [-left, top]
}

const getSiblings = (e) => {
  // for collecting siblings
  let siblings = []
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild

  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling)
    }
    sibling = sibling.nextSibling
  }
  return siblings
}

const fetchMarkerContent = (marker, location, iconPath, currentUri) => {
  if (marker.querySelector('.location-heading')) {
    return
  }
  marker.insertAdjacentHTML(
    'beforeend',
    `<div class="location-heading">
  ${iconPath ? `<img alt="icon" class="location-image" src=${iconPath} />` : ''}` +
      `<span class="location-title">${location.name.replace('-', '&#8209;').replace(/\s([^\s]*)$/, '&nbsp;$1')}</span></div>` +
      `<ul class="location-posts">
  ${location.posts?.nodes
    .map((post) => `<li><a class="location-post ${currentUri === post.uri ? 'active' : null}" href="${withPrefix(post.uri)}">${post.title}</a></li>`)
    .join('')}
  </ul></div>`
  )
}

const setOpen = (map, marker, location, iconPath, current) => {
  if (map.originalEvent?.target?.nodeName === 'A') {
    return
  }
  map = map.target ? map.target._map : map
  marker.classList.remove('dim')
  var siblings = getSiblings(marker)
  if (marker.classList.contains('open')) {
    marker.classList.remove('open')
    siblings.forEach((sib) => {
      sib.classList.remove('dim')
    })
  } else {
    siblings.forEach((sib) => {
      sib.classList.remove('open')
      if (sib.classList.contains('visible')) {
        sib.classList.add('dim')
      }
    })
    fetchMarkerContent(marker, location, iconPath, current)
    map.on('zoomend', function () {
      marker.classList.add('open')
      map.off('zoomend')
    })
    map.flyTo(topLeft(JSON.parse('[' + location.description + ']')[0]), 1)
  }
}
function getIconPath(icons, slug) {
  for (var icon of icons) {
    if (icon.name === slug) {
      return icon.publicURL
    }
  }
}

const Map = ({ locations, mapLocations, icons, currentUri }) => {
  const mapRef = useRef(null)

  useEffect(() => {
    const width = 1094
    const height = 452
    const imgBounds = new L.LatLngBounds(L.latLng([-height, 0]), L.latLng([0, width]))
    const maxBounds = new L.LatLngBounds(L.latLng([-height, -300]), L.latLng([0, width + 300]))
    const minBounds = new L.LatLngBounds(L.latLng([-height + 100, 300]), L.latLng([0 + 100, width - 300]))
    mapRef.current = L.map('map', {
      minZoom: -1,
      maxZoom: 2,
      center: [height, 0],
      zoom: 0,
      crs: L.CRS.Simple,
      scrollWheelZoom: false,
      zoomSnap: 0.1,
      tap: false,
    })
    // mapRef.current.on('zoomstart', function (map) {
    //   console.log(map)
    //   document.querySelector('#map').style.setProperty('--map-zoom', mapRef.current.getZoom())
    // })
    L.imageOverlay(withPrefix('map.svg'), imgBounds).addTo(mapRef.current)
    L.imageOverlay(withPrefix('map-terrains.svg'), imgBounds, { className: 'map-terrains' }).addTo(mapRef.current)
    window.addEventListener('resize', function () {
      mapRef.current.fitBounds(imgBounds)
    })
    mapRef.current.fitBounds(imgBounds)
    mapRef.current.setMaxBounds(maxBounds)
  }, [])

  useEffect(() => {
    locations.forEach((location) => {
      location.xy = JSON.parse('[' + location.description + ']')[0]
    })
    locations.sort((prev, next) => {
      return prev.xy && next.xy && parseFloat(prev.xy[1]) - parseFloat(next.xy[1])
    })
    locations.forEach((location) => {
      var markerImage, titlePosition, markerLatLng
      var iconPath = getIconPath(icons, location.slug.replace('-pl', ''))
      if (([markerLatLng, titlePosition, markerImage] = JSON.parse('[' + location.description + ']'))) {
        markerLatLng = Array.isArray(markerLatLng) ? markerLatLng : [0, 250]
        titlePosition ||= 'top'
        markerImage ||= 'point'
        var marker = new L.Marker(topLeft(markerLatLng), {
          icon: new L.DivIcon({
            className: `map-marker marker-${markerImage} location-${location.slug.replace('-pl', '')}`,
            html:
              `<span class="marker-image image-${markerImage}">${markerImage === 'area' ? mapAreaSVG : ''}</span>` +
              `<span class="marker-title ${titlePosition}">${location.name}</span>`,
          }),
        })
          .addTo(mapRef.current)
          .on('click', function (map) {
            setOpen(map, map.target._icon, location, iconPath, currentUri)
          })
      }
    })
  }, [locations, icons, currentUri])

  useEffect(() => {
    const width = 1094
    const height = 452
    const imgBounds = new L.LatLngBounds(L.latLng([-height, 0]), L.latLng([0, width]))
    var locationsArray = mapLocations ? mapLocations.map((location) => location.slug.replace('-pl', '')) : []
    var locationsMatch = locationsArray.length > 0 ? ', .location-' + locationsArray.join(', .location-') : ''
    var markers = document.querySelectorAll('.map-marker.visible, .map-marker.open, .map-marker.dim' + locationsMatch)
    var location = mapLocations?.length === 1 ? mapLocations[0] : null
    document.querySelector('.map-terrains').classList.toggle('visible', !mapLocations)
    for (const marker of markers) {
      marker.classList.remove('dim', 'open')
      var locationName = marker.className.match(/location-(\S+)/)[1]
      var visible = locationsArray.includes(locationName)
      if (!visible) {
        marker.classList.remove('visible')
      } else {
        marker.classList.add('visible')
        if (location) {
          var iconPath = getIconPath(icons, location.slug.replace('-pl', ''))
          setOpen(mapRef.current, marker, location, iconPath, currentUri)
        } else {
          mapRef.current.flyToBounds(imgBounds)
        }
      }
    }
  }, [mapLocations, icons, currentUri])

  return (
    <div className={styles.mapContainer}>
      <div id="map" className={styles.map}></div>
    </div>
  )
}

export default Map
