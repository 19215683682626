/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import '@fontsource/roboto'
import '@fontsource/cairo'
import '@fontsource/cairo/600.css'
import '@fontsource/cairo/700.css'
import '@fontsource/cairo/900.css'

import 'normalize.css'
import 'flickity/dist/flickity.min.css'
import '../styles/layout.sass'
import * as styles from '../styles/main.module.sass'

import Header from './Header'
import Locations from './Locations'
import Nav from './Nav'
import Footer from './Footer'

const Layout = ({ children, currentUri, isFrontPage, currentLocations, currentLang, translations, articleType }) => {
  const data = useStaticQuery(graphql`
    query mainQuery {
      wp {
        generalSettings {
          title
        }
      }
      locationsEN: allWpCategory(filter: { slug: { regex: "/^((?!-pl).)*$/" } }) {
        nodes {
          slug
          uri
          name
          id
          parentId
          description
          posts {
            nodes {
              title
              uri
              id
            }
          }
        }
      }
      locationsPL: allWpCategory(filter: { slug: { regex: "/-pl/" } }) {
        nodes {
          slug
          uri
          name
          id
          parentId
          description
          posts {
            nodes {
              title
              uri
              id
            }
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "locations" } }) {
        nodes {
          publicURL
          name
        }
      }
    }
  `)

  // (filter: { parentId: { ne: null } })

  const [mapLocations, setMapLocations] = useState(currentLocations)
  const locations = currentLang === 'pl_PL' ? data.locationsPL.nodes : data.locationsEN.nodes

  return (
    <>
      <Header
        currentUri={currentUri}
        isFrontPage={isFrontPage}
        locations={locations}
        mapLocations={mapLocations}
        mapIcons={data.allFile.nodes}
        siteTitle={data.wp.generalSettings.title || `Title`}
        currentLang={currentLang}
        translations={translations}
        articleType={articleType}
      />
      {articleType === 'page' && <Locations locations={locations} setMapLocations={setMapLocations} />}
      <main className={styles.main}>
        <Nav currentLang={currentLang} articleLocations={currentLocations} setMapLocations={setMapLocations} />
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  currentLang: `en_US`,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  currentLang: PropTypes.node.isRequired,
}

export default Layout
