import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import * as styles from '../styles/nav.module.sass'

const Nav = ({ currentLang, articleLocations, setMapLocations }) => {
  const navQuery = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: menuOrder, order: ASC }) {
        nodes {
          id
          slug
          uri
          title
          language {
            locale
          }
          categories {
            nodes {
              id
              slug
              name
              description
              posts {
                nodes {
                  id
                  slug
                  uri
                  title
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <nav id="nav" className={styles.navigation}>
      <ul>
        {navQuery.allWpPost.nodes
          .filter((post) => post.language.locale === currentLang)
          .map((post) => (
            <li key={post.id}>
              <Link
                to={post.uri}
                activeClassName={styles.active}
                onMouseEnter={() => setMapLocations(post?.categories?.nodes)}
                onMouseLeave={() => setMapLocations(articleLocations)}
              >
                {post.title}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  )
}

export default Nav
