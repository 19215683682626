import React from 'react'
import { withPrefix } from 'gatsby'

import * as styles from '../styles/footer.module.sass'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p className="footer-copy">Copyright &copy; 2021-{new Date().getFullYear()}</p>
        <ul className="footer-list">
          <li className="footer-item">
            <a href="https://www.kew.org.pl" target="_blank" rel="noreferrer">
              <img src={withPrefix('/footer/ceew-logo.svg')} alt="CEEW" />
            </a>
          </li>
          <li className="footer-item">
            <a href="https://pl.usembassy.gov/pl/embassy-consulate-pl/konsulat-usa-w-krakowie/" target="_blank" rel="noreferrer">
              <img src={withPrefix('/footer/ambasada-logo.jpg')} alt="US Embassy" />
            </a>
          </li>
        </ul>
        <p className="footer-credits">
          Author: <b>Adam Balcer</b>
          <br />
          Graphic design:{' '}
          <a href="https://dolasu-pracownia.pl/" target="_blank" title="Art studio">
            <b>DoLasu</b>
          </a>
          <br />
          Development:{' '}
          <a href="https://mobilemarkup.com" target="_blank" title="Gatsby.js & WordPress">
            <b>mobileMarkup</b>
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
