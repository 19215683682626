import React from 'react'
import PropTypes from 'prop-types'
import { Link, withPrefix } from 'gatsby'

import LanguageSwitcher from './LanguageSwitcher'
import Map from './Map'

import * as styles from '../styles/header.module.sass'

const Header = ({ siteTitle, currentUri, isFrontPage, locations, mapLocations, mapIcons, currentLang, translations, articleType }) => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Link className={styles.logo} to={currentLang === 'en_US' ? '/' : `/${currentLang.slice(0, 2)}/`}>
          <b>{articleType === 'page' ? '' : 'back to'}</b>
          <img src={withPrefix('/melting-pot-logo.svg')} alt={siteTitle} />
        </Link>
        <a
          id="nav-toggle"
          className={styles.navToggle}
          href="#nav"
          onClick={(e) => {
            e.preventDefault()
            document.querySelector('#nav-toggle').classList.toggle(styles.navToggleOpen)
            document.querySelector('main').classList.toggle('nav-open')
          }}
        >
          {' '}
          <span></span>
          <span></span>
          <span></span>
        </a>
        <LanguageSwitcher isFrontPage={isFrontPage} currentUri={currentUri} currentLang={currentLang} translations={translations} />
      </div>
      <Map locations={locations} mapLocations={mapLocations} icons={mapIcons} currentUri={currentUri} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  currentLang: `en_US`,
}

export default Header
