import React from 'react'

import { useStaticQuery, graphql, Link } from 'gatsby'

import * as styles from '../styles/language-switcher.module.sass'

const LanguageSwitcher = ({ currentUri, isFrontPage, currentLang, translations }) => {
  const langQuery = useStaticQuery(graphql`
    query {
      wp {
        languages {
          code
          locale
        }
      }
    }
  `)
  return (
    <ul className={styles.languageSwitcher}>
      {langQuery.wp.languages.map(({ code, locale }) => {
        let switcherUri = 'en_US' === locale ? '/' : `/${code}/` // defaults
        const translatedByLocale = translations?.find((t) => t.language.locale === locale)
        if (currentUri && locale === currentLang) {
          switcherUri = currentUri
        } else if (translatedByLocale) {
          switcherUri = isFrontPage ? translatedByLocale.uri.replace(`/${translatedByLocale.slug}`, '') : translatedByLocale.uri
        }
        return (
          <li key={code}>
            <Link to={switcherUri} activeClassName={styles.active}>
              {code}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default LanguageSwitcher
